/** @format */

import {
  FacebookFilled,
  InstagramOutlined,
  LinkedinFilled,
  TwitterOutlined,
  CopyrightOutlined,
  YoutubeFilled,
} from "@ant-design/icons";
import { Col, Divider, Grid, Row, Space, theme, Typography } from "antd";
import Link from "next/link";
import { locations } from "../locations";
import responsiveColumn from "../../methods/responsiveColumn";
import { useTranslation } from "next-i18next";
import Image from "next/image";

const { useToken } = theme;

export default function Footer() {
  const { token } = useToken();
  const { useBreakpoint } = Grid;
  const screenSize = useBreakpoint();
  const { t } = useTranslation("footer");
  return (
    <>
      <Row
        justify={"center"}
        style={{
          backgroundColor: token.colorPrimary,
          color: "#fff",
          paddingTop: 40,
        }}
      >
        <Col
          className="wide-content"
          {...responsiveColumn([9, 10, 11, 12, 23, 23])}
          style={{
            fontSize: 16,
            padding: "0 8px",
            display: screenSize.md ? "none" : "block",
          }}
        >
          <Link href="/">
            <div className="imageCenter">
              <Image
                src="/TWGWhiteLogo.png"
                alt="TWG-White-Logo"
                width={290}
                height={57}
                className="stext5"
              />
            </div>
          </Link>
          <div
            className="container"
            style={{
              marginTop: 20,
              marginBottom: 20,
              lineHeight: 1.5,
              textAlign: "center",
            }}
          >
            {t("company_description")}
          </div>
          <Space
            direction="vertical"
            size={"middle"}
            style={{
              marginLeft: screenSize.md ? 0 : 30,
              marginRight: screenSize.md ? 0 : 30,
            }}
          >
            <Space size="large">
              <Image
                src={"/icons/phone.svg"}
                // //preview={false}
                width={20}
                height={20}
                alt="icon"
              />
              {/* <PhoneOutlined
                    style={{ fontSize: screenSize.md ? 16 : 22 }}
                  /> */}
              <a
                href="tel:+18662829333"
                style={{ color: "white", fontSize: 16 }}
              >
                (866) 2TaxDefense (282-9333)
              </a>
            </Space>
            <Space size="large">
              <Image
                src={"/icons/email.svg"}
                // //preview={false}
                width={20}
                height={15}
                alt="icon"
              />
              {/* <MailOutlined style={{ fontSize: screenSize.md ? 16 : 22 }} /> */}
              <a
                href="mailto:contact@taxworkoutgroup.com"
                style={{ color: "white", fontSize: 16 }}
              >
                contact@taxworkoutgroup.com
              </a>
            </Space>
            <Space size="large">
              <Image
                src={"/icons/printer.svg"}
                // //preview={false}
                width={20}
                height={20}
                alt="icon"
              />
              {/* <PrinterOutlined
                    style={{ fontSize: screenSize.md ? 16 : 22 }}
                  /> */}
              <a
                href="fax:+18665112384"
                style={{ color: "white", fontSize: 16 }}
              >
                (866) 511-2384
              </a>
            </Space>
          </Space>
          <hr style={{ margin: "45px 0", borderColor: "#ffffff4f" }} />
        </Col>
        <Col
          {...responsiveColumn([20, 20, 21, 22, 22, 22])}
          style={{ textAlign: screenSize.md ? "left" : "center" }}
        >
          <Row justify={"center"} style={{ justifyContent: "space-between" }}>
            <Col
              md={{ span: 4 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              style={{ marginBottom: screenSize.md ? 20 : 50 }}
            >
              <Space direction="vertical" size={"middle"}>
                <Typography.Title
                  level={4}
                  style={{
                    color: "#fff",
                    fontWeight: screenSize.md ? 500 : 600,
                  }}
                >
                  {t("practice")}
                </Typography.Title>
                <Link
                  href="/taxbankruptcy"
                  style={{ color: "#fff", fontWeight: 200, fontSize: 16 }}
                >
                  {t("bankruptcy")}
                </Link>
                <Link
                  href="/taxcontroversy"
                  style={{ color: "#fff", fontWeight: 200, fontSize: 16 }}
                >
                  {t("controversy")}
                </Link>
                <Link
                  href="/tax-compliance"
                  style={{
                    // color: "rgba(255,255,255, .5)",
                    color: "#fff",
                    fontWeight: 200,
                    fontSize: 16,
                  }}
                >
                  {t("compliance")}
                </Link>
                <Link
                  href="/criminal-tax-defense"
                  style={{
                    // color: "rgba(255,255,255, .5)",
                    color: "#fff",

                    fontWeight: 200,
                    fontSize: 16,
                  }}
                >
                  {t("criminal")}
                </Link>
              </Space>
            </Col>
            <Col
              md={{ span: 4 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              style={{ marginBottom: screenSize.md ? 20 : 50 }}
            >
              <Space direction="vertical" size={"middle"}>
                <Typography.Title
                  level={4}
                  style={{
                    color: "#fff",
                    fontWeight: screenSize.md ? 500 : 600,
                  }}
                >
                  {t("resources")}
                </Typography.Title>
                <Link
                  href="/resources/articles"
                  style={{ color: "#fff", fontWeight: 200, fontSize: 16 }}
                >
                  {t("articles")}
                </Link>
                <Link
                  href="/resources/blog"
                  style={{ color: "#fff", fontWeight: 200, fontSize: 16 }}
                >
                  {t("blogs")}
                </Link>
                {/* <Link
                  href="/resources/assistance_resources"
                  style={{ color: "#fff", fontWeight: 200, fontSize: 16 }}
                >
                  Taxpayer Assistance Resources
                </Link> */}
                <Link
                  href="/resources/tax-resource-links"
                  style={{ color: "#fff", fontWeight: 200, fontSize: 16 }}
                >
                  {t("links")}
                </Link>
                <Link
                  href="/resources/video-library"
                  style={{ color: "#fff", fontWeight: 200, fontSize: 16 }}
                >
                  {t("video")}
                </Link>
                <Link
                  href="/resources/press-releases"
                  style={{ color: "#fff", fontWeight: 200, fontSize: 16 }}
                >
                  {t("press")}
                </Link>
              </Space>
            </Col>
            <Col
              md={{ span: 4 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              style={{ marginBottom: screenSize.md ? 20 : 50 }}
            >
              <Space direction="vertical" size={"middle"}>
                <Typography.Title
                  level={4}
                  style={{
                    color: "#fff",
                    fontWeight: screenSize.md ? 500 : 600,
                  }}
                >
                  {t("company")}
                </Typography.Title>
                <Link
                  href="/new-clients-intake-overview"
                  style={{ color: "#fff", fontWeight: 200, fontSize: 16 }}
                >
                  {t("new_clients")}
                </Link>
                <Link
                  href="/about-us/our-team"
                  style={{ color: "#fff", fontWeight: 200, fontSize: 16 }}
                >
                  {t("team")}
                </Link>
                <Link
                  href="/about-us/firm-overview"
                  style={{ color: "#fff", fontWeight: 200, fontSize: 16 }}
                >
                  {t("overview")}
                </Link>
                <Link
                  href="/faqs"
                  style={{ color: "#fff", fontWeight: 200, fontSize: 16 }}
                >
                  {t("faqs")}
                </Link>
              </Space>
            </Col>
            <Col
              md={{ span: 4 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              style={{ marginBottom: screenSize.md ? 20 : 15 }}
            >
              <Space direction="vertical" size={"large"}>
                <Typography.Title
                  level={4}
                  style={{
                    color: "#fff",
                    fontWeight: screenSize.md ? 500 : 600,
                  }}
                >
                  {t("locations")}
                </Typography.Title>
                {locations.map((location) => {
                  return (
                    <Link
                      key={location.city}
                      href={`/location/${location.slug}`}
                      style={{ color: "#fff", fontWeight: 200, fontSize: 16 }}
                    >
                      {location.city}
                    </Link>
                    // <a
                    //   href={location.direction}
                    //   target='blank'
                    //   style={{ color: "#fff", fontWeight: 200, fontSize: 16 }}
                    //   key={location.city}>
                    //   {location.city}
                    // </a>
                  );
                })}
              </Space>
            </Col>
            <Col
              md={{ span: 4 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              style={{
                marginBottom: 20,
                overflowWrap: "anywhere",
                display: screenSize.md ? "inline-table" : "none",
              }}
            >
              <Space direction="vertical" size={"middle"}>
                <Typography.Title level={4} style={{ color: "#fff" }}>
                  {t("contact")}
                </Typography.Title>
                <Link
                  href="tel:+18662829333"
                  style={{ color: "white", display: "flex", fontSize: 16 }}
                >
                  {/* <PhoneOutlined style={{ paddingRight: screenSize.md ? 10 : 5 }}/>  */}
                  <Image
                    src={"/icons/phone.svg"}
                    // //preview={false}
                    width={20}
                    height={20}
                    alt="icon"
                    style={{ marginRight: screenSize.md ? 10 : 5 }}
                  />
                  (866) 2TaxDefense (282-9333)
                </Link>
                <Link
                  href="mailto:contact@taxworkoutgroup.com"
                  style={{ color: "white", display: "flex", fontSize: 16 }}
                >
                  {/* <MailOutlined style={{ paddingRight: screenSize.md ? 10 : 5 }} /> */}
                  <Image
                    src={"/icons/email.svg"}
                    // //preview={false}
                    width={20}
                    height={15}
                    alt="icon"
                    style={{ marginRight: screenSize.md ? 10 : 5 }}
                  />
                  contact@taxworkoutgroup.com
                </Link>
                <Link
                  href="fax:+18665112384"
                  style={{
                    color: "white",
                    display: screenSize.md ? "flex" : "table",
                    fontSize: 16,
                    margin: screenSize.md ? "none" : "0 auto",
                  }}
                >
                  {/* <PrinterOutlined style={{ paddingRight: screenSize.md ? 10 : 5,  }}/>  */}
                  <Image
                    src={"/icons/printer.svg"}
                    // //preview={false}
                    width={20}
                    height={20}
                    alt="icon"
                    style={{ marginRight: screenSize.md ? 10 : 5 }}
                  />
                  (866) 511-2384
                </Link>
                <Divider
                  style={{ display: screenSize.md ? "none" : "block" }}
                />
                {/*  */}
                {/*  */}
                {/*  */}
                <div>
                  <Typography.Title
                    style={{
                      color: "#fff",
                      fontWeight: screenSize.md ? 500 : 600,
                      fontSize: screenSize.md ? 18 : 20,
                    }}
                  >
                    Hours of Operations
                  </Typography.Title>

                  <p>Monday-Friday: 8AM to 8PM</p>
                  <p>Saturday: 10AM to 6PM</p>
                  <p>Sunday: Closed</p>
                </div>
                {/*  */}
                {/*  */}
                {/*  */}
                <Space
                  style={{
                    fontSize: screenSize.md ? 22 : 24,
                    marginTop: screenSize.md ? 10 : 0,
                    gap: screenSize.md ? 9 : 24,
                  }}
                  size={"large"}
                >
                  <Link
                    href="https://www.instagram.com/taxworkoutgroup/"
                    style={{ color: "#fff" }}
                    target="_blank"
                  >
                    <InstagramOutlined />
                  </Link>
                  <Link
                    href="https://www.facebook.com/Taxworkoutgroup/"
                    style={{ color: "#fff" }}
                    target="_blank"
                  >
                    <FacebookFilled />
                  </Link>
                  <Link
                    href="https://twitter.com/Taxworkoutgroup"
                    style={{ color: "#fff" }}
                    target="_blank"
                  >
                    <TwitterOutlined />
                  </Link>
                  <Link
                    href="https://www.youtube.com/channel/UCjG4Y98ZN1vyxUWQFXAwYyA"
                    style={{ color: "#fff" }}
                    target="_blank"
                  >
                    <YoutubeFilled />
                  </Link>
                  <Link
                    href="https://www.linkedin.com/company/taxworkoutgroup/"
                    style={{ color: "#fff" }}
                    target="_blank"
                  >
                    <LinkedinFilled />
                  </Link>
                </Space>
              </Space>
            </Col>
          </Row>
        </Col>

        <Col
          {...responsiveColumn([20, 20, 21, 22, 22, 22])}
          style={{
            textAlign: screenSize.md ? "left" : "center",
            display: screenSize.md ? "inline-block" : "none",
          }}
        >
          <Divider style={{ background: "#dfdfdf" }}></Divider>
          <Row
            align={"middle"}
            style={{
              justifyContent: screenSize.md ? "space-between" : "center",
              alignItems: "baseline",
            }}
          >
            <Col
              // {...responsiveColumn([12, 12, 12, 22, 22, 22])}
              style={{ textAlign: screenSize.md ? "left" : "center" }}
            >
              <Image
                // //preview={false}
                width={80}
                src="/logo-smallf.png"
                alt="small logo"
                height={80}
              />
              ©️ {new Date().getFullYear()} Tax Workout Group
            </Col>
            <Col
              // {...responsiveColumn([12, 12, 12, 24, 24, 24])}
              style={{
                textAlign: screenSize.md ? "right" : "center",
                marginBottom: 20,
              }}
            >
              <Link style={{ color: "#fff" }} href={`/disclaimer`}>
                {t("disclaimer")}
              </Link>
              <Divider style={{ backgroundColor: "#fff" }} type="vertical" />
              <Link style={{ color: "#fff" }} href={`/privacy-policy`}>
                {t("privacy")}
              </Link>
              <Divider style={{ backgroundColor: "#fff" }} type="vertical" />
              <Link style={{ color: "#fff" }} href={`/accesibility-statement`}>
                {t("accessibility")}
              </Link>
            </Col>
          </Row>
        </Col>
        {/*  */}

        <Row
          style={{
            display: screenSize.md ? "none" : "inline-block",
            paddingBottom: 50,
          }}
        >
          <Divider style={{ background: "rgba(255, 255, 255, 0.12)" }} />
          <Col
            {...responsiveColumn([12, 12, 12, 23, 23, 23])}
            style={{ textAlign: screenSize.lg ? "left" : "center" }}
          >
            <Space size={"large"} style={{ fontSize: 24 }}>
              <Link
                href="https://www.instagram.com/taxworkoutgroup/"
                style={{ color: "#fff" }}
                target="_blank"
              >
                <InstagramOutlined
                  style={{ margin: screenSize.md ? "0px" : "0px 5px" }}
                />
              </Link>
              <Link
                href="https://www.facebook.com/Taxworkoutgroup/"
                style={{ color: "#fff" }}
                target="_blank"
              >
                <FacebookFilled
                  style={{ margin: screenSize.md ? "0px" : "0px 5px" }}
                />
              </Link>
              <Link
                href="https://twitter.com/Taxworkoutgroup"
                style={{ color: "#fff" }}
                target="_blank"
              >
                <TwitterOutlined
                  style={{ margin: screenSize.md ? "0px" : "0px 5px" }}
                />
              </Link>
              <Link
                href="https://www.youtube.com/channel/UCjG4Y98ZN1vyxUWQFXAwYyA"
                style={{ color: "#fff" }}
                target="_blank"
              >
                <YoutubeFilled
                  style={{ margin: screenSize.md ? "0px" : "0px 5px" }}
                />
              </Link>
              <Link
                href="https://www.linkedin.com/company/taxworkoutgroup/"
                style={{ color: "#fff" }}
                target="_blank"
              >
                <LinkedinFilled
                  style={{ margin: screenSize.md ? "0px" : "0px 5px" }}
                />
              </Link>
            </Space>
          </Col>
          <Col
            {...responsiveColumn([12, 12, 12, 23, 23, 20])}
            style={{
              textAlign: screenSize.lg ? "right" : "center",
              margin: screenSize.lg ? "0px" : "20px auto",
            }}
          >
            <Link href="/" className="copyRightText">
              <CopyrightOutlined style={{ color: "#fff" }} /> {new Date().getFullYear()} Tax Workout
              Group
            </Link>
            <Divider style={{ backgroundColor: "#fff" }} type="vertical" />
            <Link
              style={{ color: "#fff", fontWeight: "400" }}
              href={`/disclaimer`}
            >
              {t("disclaimer")}
            </Link>
            <Divider style={{ backgroundColor: "#fff" }} type="vertical" />
            <Link
              style={{ color: "#fff", fontWeight: "400" }}
              href={`/privacy-policy`}
            >
              {t("privacy")}
            </Link>
            <Divider style={{ backgroundColor: "#fff" }} type="vertical" />
            <Link
              style={{ color: "#fff", fontWeight: "400" }}
              href={`/accesibility-statement`}
            >
              {t("accessibility")}
            </Link>
          </Col>
        </Row>
      </Row>
    </>
  );
}
